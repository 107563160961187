import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { md, colors } from '../../common'

export default () => (
    <Layout headSize={'small'}>
      <SEO title={'Roberto Coin'} />
        <iframe title={"Roberto Coin iFrame"} css={`min-height:80vh;${md}{display:none;}`} src="https://us.robertocoin.com/retailers-embed/" width="100%" height="100%" scrolling="yes" frameborder="0"></iframe>
        <div css={`display:none;${md}{display:block;}`}>
        <div css={`padding:2rem;text-align:center;${md}{padding:2rem;}`}>
          <img css={`max-width:400px;width:100%;`} src={`https://s3.amazonaws.com/underwoods/_designers/roberto-coin/logo.png`} alt={`logo for Roberto Coin`} />
          <div css={`color:${colors.greyDarker};padding:2rem;line-height:24px;${md}{padding:1rem;}`} >
            <p>Underwood's is the only Roberto Coin retailer in Jacksonville. This Italian gold jewelry designer is featured in all of our locations. Roberto Coin adds a signature ruby to each of his designs which touches the wearer's skin and represents good fortune.</p>
          </div>
          <img css={`max-width:800px;width:100%;`} src={`https://s3.amazonaws.com/underwoods/_designers/roberto-coin/feat.jpg`} alt={`Roberto Coin`} />
        </div>
        </div>
    </Layout>
)
